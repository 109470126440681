<!-- 异常订单 -->
<template>
  <section class="cont abnormalOrders">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <el-row class="left-btn">
            <span class="m-right-15">订单日期</span>
            <el-date-picker v-model="date" type="daterange" align="left" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" 
              :picker-options="pickerOptions" value-format="yyyy-MM-dd" :clearable="false"></el-date-picker>
            <el-button class="m-left-15" type="primary" @click="getData"><span>搜索</span></el-button>
          </el-row>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border fit stripe v-loading="loading">
          <el-table-column label="营业时间">
            <template slot-scope="scope">
              {{ scope.row.createTime.split(' ')[0] }}
            </template>
          </el-table-column>
          <el-table-column prop="bookInfo.mobile" label="预定电话"></el-table-column>
          <el-table-column label="订单来源">
            <template slot-scope="scope">
              {{ scope.row.bookInfo.channel | filterChannel(that) }}
            </template>
          </el-table-column>
          <el-table-column prop="bookInfo.realName" label="姓名"></el-table-column>
          <el-table-column label="房间号">
            <template slot-scope="scope">
              {{ scope.row.bookInfo.checkinRoomDTOList[0].roomNo || '未排房' }}
            </template>
          </el-table-column>
          <el-table-column label="到店/离店">
            <template slot-scope="scope">
              {{ arrivalTime(scope.row.bookInfo.checkinRoomDTOList[0]) }}
            </template>
          </el-table-column>
          <el-table-column label="房费">
            <template slot-scope="scope">￥{{ scope.row.bookInfo.amountsPayable }}</template>
          </el-table-column>
          <el-table-column label="其它消费">
            <template slot-scope="scope">￥{{ scope.row.bookInfo.consumptionDTOList.length == 0 ? '0' : scope.row.bookInfo.consumptionDTOList[0].amount }}</template>
          </el-table-column>
          <el-table-column label="已收费">
            <template slot-scope="scope">￥{{ scope.row.bookInfo.actualAmount }}</template>
          </el-table-column>
          <el-table-column label="入住状态">
            <template slot-scope="scope">
              {{ scope.row.bookInfo.checkinRoomDTOList[0].checkInState | filterCheckInState }}
            </template>
          </el-table-column>
          <el-table-column label="异常原因">
            <template slot-scope="scope">
              {{ scope.row.reason | filterReason }}
            </template>
          </el-table-column>
          <el-table-column label="当前状态">
            <template slot-scope="scope">
              <div :class="scope.row.status === 0 ? '' :'c-red'">
                {{ scope.row.status | filterStatus }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="nightAuditLog(scope.row)">
                <el-link type="primary">查看订单</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination v-show="total" :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" />
      </el-row>
    </el-row>
    <!-- 订单详情 抽屉 -->
    <OrderDetails :orderDetailsBool="orderDetailsBool" :bookId="bookId" @closeOrderDetails="closeOrderDetails" :abnormalOrders="true"></OrderDetails>
  </section>
</template>

<script>
  import OrderDetails from '@/components/local/atrialState/OrderDetails'
  import { getbookInfoPage } from '@/api/pms/nightAudit/nightAudit'
  import { dateFactory } from '@/common/js/common'
  import { mapState } from 'vuex'
  export default {
    name: 'abnormalOrders',
    components: { OrderDetails },
    data() {
      return {
        crumbs: new Map([['PMS'], ['夜审中心'], ['异常订单']]),
        pickerOptions: {
          shortcuts: [
            {
              text: '当日',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 0)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '当月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * (end.getDate() - 1))
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一周',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                picker.$emit('pick', [start, end])
              },
            },
            {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date()
                const start = new Date()
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                picker.$emit('pick', [start, end])
              },
            },
          ],
          disabledDate(time) {
            return time.getTime() >= Date.now()
          },
        },
        date: [this.$route.query.date || dateFactory.dateFormat(false), this.$route.query.date || dateFactory.dateFormat(false)],
        tableData: [],
        that: this,
        loading: true,
        // 订单详情 抽屉
        orderDetailsBool: false, // 是否显示 订单详情 抽屉
        bookId: '',              // 订单id

        total: 0,                // 用户列表总条目数
        page: 1,                 // 当前页 默认第一页
        limit: 10,               // 每页显示数
      }
    },
    computed: {
      ...mapState(['dictData','hotelInfo']),
      arrivalTime() {
        return (item) => {
          return item.startTime.split(' ')[0].split('-').join('/').substring(5) + '-' + item.endTime.split(' ')[0].split('-').join('/').substring(5)
        }
      },
    },
    mounted() {
      this.getData()
    },
    methods: {
      // 订单列表
      getData() {
        let params = {
          limit: this.limit,
          page: this.page,
        }
        let data = {
          hotelId: this.hotelInfo.id,
          beginTime: this.date[0] + ' 00:00:00',
          endTime: this.date[1] + ' 23:59:59',
        }
        getbookInfoPage(params, data).then(({ success, records, total }) => {
          if (success) {
            this.loading = false
            this.total = total
            this.tableData = records
          }
        })
      },
      // 查看订单
      nightAuditLog(item) {
        console.log(item)
        this.orderDetailsBool = true
        this.bookId = item.bookInfoId
      },
      // 关闭 订单详情 抽屉
      closeOrderDetails(val, bookId) {
        this.orderDetailsBool = val
        this.bookId = bookId
        this.getData()
      },
      // 改变每页数
      pageChange(num) {
        this.limit = num
        this.getData()
      },
      // 改变当前页
      handlePaging(num) {
        this.page = num
        this.getData()
      },
    },
    filters: {
      // 渠道
      filterChannel(val,that){
        if(val && that.dictData.hasOwnProperty('order-source-list')){
          if (that.dictData['order-source-list'].length){
            that.dictData['order-source-list'].forEach(item=>{
              if(item.value === val)  val = item.label
            })
            return val
          }
        }
      },
      // 入住状态
      filterCheckInState(val) {
        switch (val) {
          case 'NOT_CHECKIN':
            return '未入住'
          case 'CHECKIN':
            return '已入住'
          case 'CHECKOUT':
            return '已退房'
          case 'CANCEL':
            return '已取消'
          default:
            return '-'
        }
      },
      filterReason(val){
        switch (val) {
          case 1:
            return '未入住'
          case 2:
            return '未退房'
          case 3:
            return '未平账'
          default:
            return '-'
        }
      },
      // 当前状态
      filterStatus(val) {
        switch (val) {
          case 0:
            return '正常'
          case 1:
            return '异常'
          case 2:
            return '取消单'
          default:
            return '-'
        }
      },
    },
  }
</script>
